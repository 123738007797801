import { FC } from "react"
import { IFormProps } from "@/core/interfaces"
import { RtkHookForm } from "@/core/components"
import { ADV } from "../../interfaces"
import { useUpdateOrderMutation } from "../../store"
import { Grid, Typography } from "@mui/material"
import { DefaultValues } from "react-hook-form-mui"
import { Links } from "../formFields/links"
import { EMPTY_LINK, EMPTY_URL } from "../../constants/adv"
import { Urls } from "../formFields/urls"

interface IProps extends IFormProps<ADV.CreateOrderRes, ADV.UpdateOrderRes> {
  id: ADV.Order["id"]
  form: "bot" | "url"
}
interface IFormData extends ADV.UpdateOrder {}

export const UpdateOrderBotsForm: FC<IProps> = ({id, form}) => {
  const DEFAULT_FORM_VALUES: DefaultValues<IFormData> = form === "bot" 
    ? { links: [{ ...EMPTY_LINK }] }
    : { urls: [{...EMPTY_URL}] }

  return (
    <RtkHookForm<IFormData, ADV.UpdateOrderRes, typeof useUpdateOrderMutation>
      mutationHook={useUpdateOrderMutation}
      getSubmitHandlerParams={(body) => ({
        body,
        pathParams: { id },
      })}
      formContextParams={{
        defaultValues: DEFAULT_FORM_VALUES,
      }}
      actions={[
        {
          text: "Сохранить",
          type: "submit",
          variant: "contained",
          fullWidth: true,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={4}>
        {form === "bot" 
        ? <>
          <Typography variant="body1" pb={2}>
            Ссылки:
          </Typography>
          <Links<IFormData> minLength={1} />
        </>
        : <>
          <Typography variant="body1" pb={2}>
            Дополнительные ссылки:
          </Typography>
          <Urls<IFormData> minLength={1} />
        </>
        }
        </Grid>
      </Grid>
    </RtkHookForm>
  )
}
