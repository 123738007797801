import { Stack, Button, Box } from "@mui/material"
import { FieldValues, useFormContext } from "react-hook-form-mui"
import { Type } from "./typeSelect"
import { RefCode } from "./refCodesSelect"
import { EMPTY_URL } from "@/modules/adv2/constants/adv"
import { IOrderFormData } from "../forms/createOrderForm"
import { Bot } from "./botSelect"
import { ADV } from "../../interfaces"
import { Url } from "./url"
import { Utm } from "./utm"

interface IProps<T extends FieldValues> {
  minLength?: number
}
interface IField {
  urls: ADV.Url[]
}

export function Urls<T extends FieldValues = IField>(
  props: IProps<T>,
): JSX.Element {
  const ctx = useFormContext<IOrderFormData>()
  const urls = ctx?.watch("urls")

  const deleteLink = (index: number) => {
    const newValue = urls?.filter((v, i) => i !== index)
    ctx.setValue(
      "urls",
      Array.isArray(newValue) && newValue.length > 0 ? newValue : undefined,
    )
  }

  const minArrLength =
    props.minLength !== undefined && props.minLength > -1 ? props.minLength : 1

  return (
    <Stack spacing={4}>
      {urls?.map((link, i) => (
        <Stack spacing={2} key={i}>
          <Url<IOrderFormData>
            name={`urls.${i}.url`}
            required
          />
          <Utm<IOrderFormData>
            name={`urls.${i}.utm`}
          />
          {urls?.length > minArrLength && (
            <Box display="flex" flexDirection="row-reverse">
              <Button variant="text" onClick={() => deleteLink(i)}>
                Удалить
              </Button>
            </Box>
          )}
        </Stack>
      ))}
      <Button
        onClick={() => ctx.setValue("urls", [...(urls ?? []), EMPTY_URL])}
      >
        Добавить дополнительную ссылку
      </Button>
    </Stack>
  )
}
