import { ADV } from "../../interfaces"
import { AutocompleteElement } from "react-hook-form-mui"
import { ListboxComponent } from "@core/components"
import { useAgentsListQuery } from "../../store"

interface IProps<M extends boolean> {
  multiple?: M extends true ? true : never
  required?: boolean
  readOnly?: boolean
}
interface IAdAgentsSelect {
  agent_ids: ADV.Agent["id"][]
}
interface IAdAgentSelect {
  agent_id: ADV.Agent["id"]
}
type IField<M extends boolean> = (M extends true
  ? IAdAgentsSelect
  : IAdAgentSelect) & {}

export const Agent = <T extends IField<M>, M extends boolean = false>(
  props: IProps<M>,
): JSX.Element => {
  const { multiple, required, readOnly } = props
  const { data, isFetching: isLoading } = useAgentsListQuery({})
  const idKey = (
    multiple ? "agent_ids" : "agent_id"
  ) satisfies keyof (IAdAgentSelect & IAdAgentsSelect)

  return (
    <AutocompleteElement
      name={idKey}
      label={multiple ? "Контрагенты" : "Контрагент"}
      loading={isLoading}
      multiple={multiple}
      showCheckbox={multiple}
      required={required}
      matchId
      autocompleteProps={{
        readOnly,
        fullWidth: true,
        getOptionLabel: (option: ADV.AgentsListRes[number]) =>
          option ? `${option.name}` : "Загрузка...",
        ListboxComponent: ListboxComponent,
        renderOption: (props, option, state) =>
          [props, `${option.name}`, state] as React.ReactNode,
      }}
      textFieldProps={{
        helperText: "",
      }}
      options={data ?? []}
    />
  )
}
