import { AutocompleteElement, FieldValues } from "react-hook-form-mui"
import { IMailingFormData } from "./manual/mailingForm"
import { ParseMode } from "typegram"

interface IProps {}
interface IField extends Pick<IMailingFormData, "method"> {}

const PARSE_MODE: Record<ParseMode, ParseMode> = {
  Markdown: "Markdown",
  MarkdownV2: "Markdown",
  HTML: "Markdown",
}
export const ParseModeSelectField = <T extends FieldValues = IField>(
  props: IProps,
) => {
  return (
    <AutocompleteElement
      name="body.parse_mode"
      label="Форматирование"
      options={Object.keys(PARSE_MODE)}
    />
  )
}
