import { FieldValues, Path, TextFieldElement } from "react-hook-form-mui";

interface IProps<T extends FieldValues> {
  name: Path<T>;
  required?: boolean;
}
interface IField {
  utm: string;
}

export function Utm<T extends FieldValues = IField> ({required, name}: IProps<T>): JSX.Element {
  return <TextFieldElement<T>
    name={name}
    label='Utm метка'
    required={required}
    fullWidth
  />
}
