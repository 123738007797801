import { FC } from "react"
import { IFormProps } from "@/core/interfaces"
import { RtkHookForm } from "@/core/components"
import { ADV } from "../../interfaces"
import { useUpdateOrderMutation } from "../../store"
import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import { Status } from "../formFields/statusSelect"
import { Payment } from "../formFields/payment"
import { OrderExtensionStartDate } from "../formFields/orderExtensionStartDate"
import { OrderExtensionEndDate } from "../formFields/orderExtensionEndDate"
import { DefaultValues } from "react-hook-form-mui"
import { Links } from "../formFields/links"
import { DEFAULT_PAYMENT_SUM, HAS_PAYED_STATUS_ID } from "../../constants/adv"
import { TransactionHash } from "../formFields/hash"
import { SumToken } from "../formFields/sumToken"
import { Type } from "../formFields/typeSelect"
import { Comment } from "../formFields/comment"
import { encryptTransactionId } from "../utils/string"
import { Urls } from "../formFields/urls"

interface IProps extends IFormProps<ADV.CreateOrderRes, ADV.UpdateOrderRes> {
  id: ADV.Order["id"]
}
interface IFormData extends ADV.UpdateOrder {}

export const UpdateOrderExtensionsForm: FC<IProps> = (props) => {
  const DEFAULT_FORM_VALUES: DefaultValues<IFormData> = {
    payment: { date_payment: new Date(), sum: DEFAULT_PAYMENT_SUM },
    status_id: HAS_PAYED_STATUS_ID,
    extensions: {
      date_start: new Date(),
    },
  }

  return (
    <RtkHookForm<IFormData, ADV.UpdateOrderRes, typeof useUpdateOrderMutation>
      mutationHook={useUpdateOrderMutation}
      getSubmitHandlerParams={async (body) => (console.log(body), {
        body: {
          ...body,
          payment: {
            ...body.payment,
            hash: await encryptTransactionId(body.payment.hash),
          },
        },
        pathParams: { id: props.id },
      })}
      formContextParams={{
        defaultValues: DEFAULT_FORM_VALUES,
      }}
      actions={[
        {
          text: "Сохранить",
          type: "submit",
          variant: "contained",
          fullWidth: true,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <Stack spacing={1}>
            <Status<IFormData> for="orders" required />
            <Payment<IFormData> required />
            <Type<IFormData>
              required
              name={"payment.type_id"}
              for={"payments"}
              label="Монета"
            />
            <TransactionHash<IFormData> name={"payment.hash"} required />
            <SumToken<IFormData> name={"payment.sum_token"} required />
            <Comment<IFormData> name={"payment.comment"} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Stack spacing={1}>
            <OrderExtensionStartDate<IFormData> required />
            <OrderExtensionEndDate<IFormData> />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Stack spacing={4}>
            <Box>
              <Typography variant="body1" pb={2}>
                Ссылки:
              </Typography>
              <Links<IFormData> minLength={0} />
            </Box>
            <Divider />
            <Box>
              <Typography variant="body1" pb={2}>
                Дополнительные ссылки:
              </Typography>
              <Urls<IFormData> minLength={0} />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </RtkHookForm>
  )
}
